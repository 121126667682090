import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { MenuItem, Select, TableBody, Table, TableContainer, CircularProgress, TableCell, Paper, TableHead, TableRow } from '@material-ui/core'
import { formattedTime } from 'utils'
import Main from 'components/main'
import { get } from 'utils/request'

const Fees = () => {
  const [loading, setLoading] = useState(true)
  const [year, setYear] = useState(dayjs().year())
  const [month, setMonth] = useState(dayjs().month() + 1)
  const [enterpriseSettlements, setEnterpriseSettlements] = useState([])
  const totalFees = enterpriseSettlements.reduce((sum, fee) => sum + fee.amount, 0) / 100

  const getEnterpriseSettlements = async (params) => {
    const res = await get('managers/settlements', params)
    if (res.ok) {
      setEnterpriseSettlements(res.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    getEnterpriseSettlements({ year, month })
  }, [year, month])

  return (
    <Main>
      <div className="text-lg font-bold mb-6">{`总费用：${totalFees} 元`}</div>
      <Select className="mb-6 w-24" defaultValue={dayjs().year()} onChange={(e) => setYear(e.target.value)}>
        <MenuItem value={dayjs().year() - 2}>{dayjs().year() - 2}</MenuItem>
        <MenuItem value={dayjs().year() - 1}>{dayjs().year() - 1}</MenuItem>
        <MenuItem value={dayjs().year()}>{dayjs().year()}</MenuItem>
      </Select>
      <Select className="mb-6 w-24" defaultValue={month} onChange={(e) => setMonth(e.target.value)}>
        {[...Array(12).keys()].map((v) => (
          <MenuItem key={v + 1} value={v + 1}>
            {v + 1}月
          </MenuItem>
        ))}
      </Select>
      <div className="text-center">
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>时间</TableCell>
                  <TableCell>时长</TableCell>
                  <TableCell>人数</TableCell>
                  <TableCell>金额</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enterpriseSettlements.map((v) => (
                  <TableRow key={v.created_at}>
                    <TableCell align="left">{formattedTime(v.created_at)}</TableCell>
                    <TableCell align="left">{`${v.hours}小时`}</TableCell>
                    <TableCell align="left">{v.total_users}</TableCell>
                    <TableCell align="left">{`¥ ${v.amount / 100}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Main>
  )
}

export default Fees
