import dayjs from 'dayjs'

export const formattedTime = (v) => dayjs(v).format('YY-MM-DD HH:mm')

export function getDay(time) {
  return dayjs(time).format('YYYY-MM-DD')
}

export function isAndroid() {
  if (typeof window !== `undefined`) {
    return /Android/i.test(navigator.userAgent)
  }
  return null
}

export function isWechat() {
  if (typeof window !== `undefined`) {
    return /micromessenger/.test(navigator.userAgent.toLowerCase())
  }
  return null
}

export function getRoot() {
  const { protocol, host } = global.location
  return `${protocol}//${host}`
}
